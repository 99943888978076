.list-group-item{
    text-overflow: ellipsis;
    border:0;
    color:#363434;
}
.list-group{
    border:0;
    border-radius: 0;
}
.heading{
    font-weight: bold;
    text-transform: capitalize;
}
.overflow-auto{
    max-height: 100%;
}
.list-group-item .btn{
    border-radius: 100%!important;
    width:26px;
    height:26px;
    margin: 4px;
    padding: 2px;
}
.list-group.active{
    background-color: rgb(241, 205, 44);
}
.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    padding: 2px!important;
}
/* .list-group-horizontal:hover>.list-selector {
    background-color: #366274;
} */

  