* {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

body {
  width: available;
}

.card {
  border: 0 !important;
  border-radius: 0 !important;

}

.sub-title {
  color: #8BC34A;
}

/* .hr{
    color:#cbc8c8;
} */
.overflow-auto {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 78vh !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: block;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

td,
th {
  padding-left: 4px;
  padding-right: 4px;
  ;
}